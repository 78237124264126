.flip-card-wrapper {
    text-align: center;
    width: 80%;
    margin: 150px auto auto;
}

.flip-card{
    /*max-width: 48%;*/
    width: 48%;
    margin-left: 2%;
    margin-bottom: 2%;
    display: inline-block;
}

.flip-card .cols{
    display: -webkit-box;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flip-card .col{
    cursor: pointer;
}

.flip-card .container{
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.flip-card .front,
.back{
    background-size: cover;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
    border-radius: 10px;
    background-position: center;
    -webkit-transition: -webkit-transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: -webkit-transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
    -o-transition: transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform .9s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-align: center;
    min-height: 550px;
    height: auto;
    border-radius: 10px;
    color: #fff;
    font-size: 1.5rem;
}

.flip-card .back{
    background: #cedce7;
    background: -webkit-linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
    background: -o-linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
    background: linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
}

.flip-card .front:after{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    opacity: .6;
    background-color: #000;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 10px;
}
.flip-card .container:hover .front,
.flip-card .container:hover .back{
    -webkit-transition: -webkit-transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: -webkit-transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
    -o-transition: transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform .9s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.flip-card .back{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.flip-card .inner{
    -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
    transform: translateY(-50%) translateZ(60px) scale(0.94);
    top: 50%;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 2rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
}

.flip-card .container .back{
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-card .container .front{
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-card .container:hover .back{
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-card .container:hover .front{
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-card .front .inner p{
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
}

.flip-card .front .inner p:after{
    content: '';
    width: 4rem;
    height: 2px;
    position: absolute;
    background: #C6D4DF;
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -.75rem;
}

.flip-card .front .inner span{
    color: rgba(255,255,255,0.7);
    font-family: 'Montserrat';
    font-weight: 300;
}

@media screen and (max-width: 900px){
    .flip-card .col{
        width: 100%;
    }

    .flip-card {
        width: 100%;
    }
}

@media screen and (max-width: 32rem){
    .flip-card .col{
        width: 100%;
        margin: 0 0 2rem 0;
    }
}