
/*
 ====================================================

    BUTTON Styling

 ====================================================
*/
.t-dispatch-button-primary {
    border-radius: 10px;
    font-size: 18px;
    padding: 3px 9px;
    line-height: 40px;
    background-color: #0076cd;
    color: white;
    font-family: 'Courier New', monospace, sans-serif;
    font-weight: 500;
    letter-spacing: 0.2px;
    border: solid 1px #0076cd;
    text-decoration: none;
    display: inline-block;
}

.t-dispatch-button-primary:hover {
    border: solid 1px #0076cd;
    cursor: pointer;
}

.t-dispatch-button-secondary {
    border-radius: 10px;
    font-size: 18px;
    padding: 3px 9px;
    line-height: 40px;
    background-color: white!important;
    color: #0076cd!important;
    font-family: 'Courier New', monospace, sans-serif;
    font-weight: 500;
    letter-spacing: 0.2px;
    border: solid 1px #d0d0d0;
    text-decoration: none;
}

.t-dispatch-button-secondary:hover {
    border: solid 1px #0076cd;
    cursor: pointer;
}

.section-title {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 45px;
}

.section-title h2 {
    font-weight: 900;
    font-size: 45px;
    line-height: 1.3;
    color: #333;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 750px) {
    .t-dispatch-button-primary, .t-dispatch-button-secondary {
        font-size: 15px;
        line-height: 30px;
        margin-top: 10px;
    }
}