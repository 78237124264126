
.dots-bars-7 {
    width: 40px;
    height: 40px;
    --c: linear-gradient(currentColor 0 0);
    background: var(--c) 0 0,
    var(--c) 0 100%,
    var(--c) 50% 0,
    var(--c) 50% 100%,
    var(--c) 100% 0,
    var(--c) 100% 100%;
    background-size: 8px 50%;
    background-repeat: no-repeat;
    animation: db7-0 7s infinite;
    position: relative;
    overflow: hidden;
}

.dots-bars-7:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: currentColor;
    top: calc(50% - 4px);
    left: -8px;
    animation: inherit;
    animation-name: db7-1;
    color: white!important;
}

@keyframes db7-0 {
    16.67% {
        background-size: 8px 30%, 8px 30%, 8px 50%, 8px 50%, 8px 50%, 8px 50%
    }
    33.33% {
        background-size: 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 50%, 8px 50%
    }
    50% {
        background-size: 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 30%
    }
    66.67% {
        background-size: 8px 50%, 8px 50%, 8px 30%, 8px 30%, 8px 30%, 8px 30%
    }
    83.33% {
        background-size: 8px 50%, 8px 50%, 8px 50%, 8px 50%, 8px 30%, 8px 30%
    }
}

@keyframes db7-1 {
    20% {
        left: 0px
    }
    40% {
        left: calc(50% - 4px)
    }
    60% {
        left: calc(100% - 8px)
    }
    80%,
    100% {
        left: 100%
    }
}


/*Circle to Square animation: Source https://codepen.io/Edgardo/pen/jPNdjq*/
.square-to-circle-animation {
    width: 40px;
    height: 40px;
    background-color: #0076cd;
    border: 4px solid #ffffff;
    animation: square-to-circle 1s .83s infinite cubic-bezier(1, .015, .295, 1.225) alternate;
}

@keyframes square-to-circle {
    0% {
        border-radius: 0 0 0 0;
        background: #0076cd;
        transform: rotate(0deg);
    }
    25% {
        border-radius: 25% 25% 25% 25%;
        background: #0076cd;
        transform: rotate(45deg);
    }
    50% {
        border-radius: 50% 50% 50% 50%;
        background: #0076cd;
        transform: rotate(90deg);
    }
    75% {
        border-radius: 75% 75% 75% 75%;
        background: #0076cd;
        transform: rotate(180deg);
    }
    100% {
        border-radius: 100% 100% 100% 100%;
        background: #0076cd;
        transform: rotate(360deg);
    }
}
