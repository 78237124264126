.hide-loader {
    display: none;
}

.show-loader {
    display: flex;
}

#submission-loader {
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    justify-content: center;
}

#submission-loader .loop-wrapper {
    margin: auto;
    position: relative;
    display: block;
    width: 600px;
    height: 250px;
    overflow: hidden;
    border-bottom: 3px solid #fff;
    color: #fff;
    opacity: 1;
}
#submission-loader .loop-wrapper .mountain {
    position: absolute;
    right: -900px;
    bottom: -20px;
    width: 2px;
    height: 2px;
    box-shadow:
            0 0 0 50px #0092ff,
            60px 50px 0 70px #0092ff,
            90px 90px 0 50px #0092ff,
            250px 250px 0 50px #0092ff,
            290px 320px 0 50px #0092ff,
            320px 400px 0 50px #0092ff
;
    transform: rotate(130deg);
    animation: mtn 20s linear infinite;
}
#submission-loader .loop-wrapper .hill {
    position: absolute;
    right: -900px;
    bottom: -50px;
    width: 400px;
    border-radius: 50%;
    height: 20px;
    box-shadow:
            0 0 0 50px #0092ff,
            -20px 0 0 20px #0092ff,
            -90px 0 0 50px #0092ff,
            250px 0 0 50px #0092ff,
            290px 0 0 50px #0092ff,
            620px 0 0 50px #0092ff;
    animation: hill 4s 2s linear infinite;
}
#submission-loader .loop-wrapper .tree, .tree:nth-child(2), .tree:nth-child(3) {
    position: absolute;
    height: 100px;
    width: 35px;
    bottom: 0;
    background: url(../../assets/contact/tree.svg) no-repeat;
}
#submission-loader .loop-wrapper .rock {
    margin-top: -17%;
    height: 2%;
    width: 2%;
    bottom: -2px;
    border-radius: 20px;
    position: absolute;
    background: #ddd;
}
#submission-loader .loop-wrapper .truck, .wheels {
    transition: all ease;
    width: 85px;
    margin-right: -60px;
    bottom: 0px;
    right: 50%;
    position: absolute;
    background: #eee;
}
#submission-loader .loop-wrapper .truck {
    background: url(../../assets/contact/truck.svg) no-repeat;
    background-size: contain;
    height: 60px;
}
#submission-loader .loop-wrapper .truck:before {
    content: " ";
    position: absolute;
    width: 25px;
    box-shadow:
            -30px 28px 0 1.5px #fff,
            -35px 18px 0 1.5px #fff;
}
#submission-loader .loop-wrapper .wheels {
    background: url(../../assets/contact/wheels.svg) no-repeat;
    height: 15px;
    margin-bottom: 0;
}

#submission-loader .loop-wrapper .tree  { animation: tree 3s 0.000s linear infinite; }
#submission-loader .loop-wrapper .tree:nth-child(2)  { animation: tree2 2s 0.150s linear infinite; }
#submission-loader .loop-wrapper .tree:nth-child(3)  { animation: tree3 8s 0.050s linear infinite; }
#submission-loader .loop-wrapper .rock  { animation: rock 4s   -0.530s linear infinite; }
#submission-loader .loop-wrapper .truck  { animation: truck 4s   0.080s ease infinite; }
#submission-loader .loop-wrapper .wheels  { animation: truck 4s   0.001s ease infinite; }
#submission-loader .loop-wrapper .truck:before { animation: wind 1.5s   0.000s ease infinite; }


@keyframes tree {
    0%   { transform: translate(1350px); }
    50% {}
    100% { transform: translate(-50px); }
}
@keyframes tree2 {
    0%   { transform: translate(650px); }
    50% {}
    100% { transform: translate(-50px); }
}
@keyframes tree3 {
    0%   { transform: translate(2750px); }
    50% {}
    100% { transform: translate(-50px); }
}

@keyframes rock {
    0%   { right: -200px; }
    100% { right: 2000px; }
}
@keyframes truck {
    0%   { }
    6%   { transform: translateY(0px); }
    7%   { transform: translateY(-6px); }
    9%   { transform: translateY(0px); }
    10%   { transform: translateY(-1px); }
    11%   { transform: translateY(0px); }
    100%   { }
}
@keyframes wind {
    0%   {  }
    50%   { transform: translateY(3px) }
    100%   { }
}
@keyframes mtn {
    100% {
        transform: translateX(-2000px) rotate(130deg);
    }
}
@keyframes hill {
    100% {
        transform: translateX(-2000px);
    }
}