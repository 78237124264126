.service-highlight {
    width: 100%;
    height: 500px;
    background-image: url("../../../assets/5.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    padding: 0;
    margin: 0;
}

.service-highlight .image-hover {
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    float: left;
    display: flex;
    justify-content: left;
}

.service-highlight .image-hover .column {
    margin-left: 15%;
    width: 70%;
    margin-top: 5%;
}

.service-highlight .image-hover .column .heading {
    display: flex;
    justify-content: center;
    padding: 30px;
    border-bottom: 5px solid #0076cd;
    width: 50%;
    margin-left: 25%;
}

.service-highlight .image-hover .column .heading .square-to-circle-animation {
    margin-right: 30px;
}

.service-highlight .image-hover .column .heading h3 {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 42px;
    text-transform: capitalize;
    color: white;
}

.service-highlight .image-hover .column .description {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
    /*text-transform: capitalize;*/
    color: white;
    text-align: justify;
    padding: 20px 40px;
    width: 90%;
    margin-left: 5%;
}

@media only screen and (max-width: 950px) {

    .service-highlight .image-hover .column .heading {
        width: 72%;
        margin-left: 14%;
    }

    .service-highlight .image-hover .column .description {
        width: 100%;
        margin-left: unset;
        font-size: 22px;
    }
}


@media only screen and (max-width: 760px) {

    .service-highlight {
        width: 100%;
        height: 400px;
        background-image: url("../../../assets/5.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        margin: 0;
    }

    .service-highlight .image-hover .column {
        width: 100%;
        margin-left: unset;
        margin-right: unset;
    }

    .service-highlight .image-hover .column .heading {
        display: flex;
        justify-content: center;
        padding: 20px;
        border-bottom: 4px solid #0076cd;
        width: 70%;
        margin-left: 15%;
        margin-top: 5%;
    }

    .service-highlight .image-hover .column .heading .square-to-circle-animation {
        margin-right: 30px;
    }

    .service-highlight .image-hover .column .heading h3 {
        font-family: 'Josefin Sans', sans-serif;
        font-size: 30px;
        text-transform: capitalize;
        color: white;
    }

    .service-highlight .image-hover .column .description {
        font-family: 'Josefin Sans', sans-serif;
        font-size: 20px;
        text-transform: capitalize;
        color: white;
        text-align: justify;
        padding: 20px 40px;
        width: 90%;
        margin-left: 5%;
    }
}


@media only screen and (max-width: 500px) {
    .service-highlight {
        width: 100%;
        height: 500px;
        background-image: url("../../../assets/5.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        margin: 0;
    }

    .service-highlight .image-hover .column {
        width: 100%;
        margin-left: unset;
        margin-right: unset;
    }

    .service-highlight .image-hover .column .heading {
        display: flex;
        justify-content: center;
        padding: 20px;
        border-bottom: 4px solid #0076cd;
        width: 90%;
        margin-left: 5%;
        margin-top: 5%;
    }
}