

.equipment {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
}

.equipment .equipmentWidth {
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.equipment .equipmentWidth h2 {
    color: #014374;
    margin: 50px;
}

.equipment .equipmentWidth .equipmentCards {
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.equipment .equipmentWidth .equipmentCard {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 220px;
    border-radius: 10px;
    box-shadow: 0px 5px 14px rgba(145, 145, 145, 0.7);
    transition: 0.3s ease-out;
    margin: 15px;
}

.equipment .equipmentWidth .equipmentCardHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #014374;
    font-size: 26px;
    font-weight: 700;
    height: 60px;
    width: 250px;
    border-radius: 10px 10px 0px 0px;
}

.equipment .equipmentWidth .equipmentCardImage {
    height: 160px;
    background-color: white;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
}

.equipment .equipmentWidth img {
    height: 60px;
}


.equipment .equipmentWidth .equipmentCard:hover {
    transform: scale(1.1);
    box-shadow: 0px 8px 14px rgba(145, 145, 145, 0.7);
}

@media screen and (max-width: 1320px) {
    .equipment {
        margin-top: 10px;
    }

    .equipment .equipmentWidth {
        width: 900px;
    }

    .equipment .equipmentWidth h2 {
        font-size: 35px;
    }

    .equipment .equipmentWidth .equipmentCards {
        width: 900px;
    }

    .equipment .equipmentWidth .equipmentCard {
        width: 200px;
        height: 180px;
    }

    .equipment .equipmentWidth .equipmentCardHeading {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: #014374;
        font-size: 22px;
        font-weight: 700;
        height: 40px;
        width: 200px;
        border-radius: 10px 10px 0px 0px;
    }

    .equipment .equipmentWidth .equipmentCardImage {
        height: 140px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px 0px 10px 10px;
    }

    .equipment .equipmentWidth img {
        height: 50px;
    }
}

@media screen and (max-width: 960px) {
    .equipment {
        width: 100%;
    }

    .equipment .equipmentWidth {
        width: 800px;
    }

    .equipment .equipmentWidthh2 {
        font-size: 30px;
        text-align: center;
    }

    .equipment .equipmentWidth .equipmentCards {
        width: 750px;
    }

    .equipment .equipmentWidth .equipmentCard {
        width: 350px;
        height: 150px;
    }

    .equipment .equipmentWidth .equipmentCardHeading {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: #014374;
        font-size: 18px;
        font-weight: 700;
        height: 40px;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
    }

    .equipment .equipmentWidth .equipmentCardImage {
        height: 140px;
        width: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px 0px 10px 10px;
    }

    .equipment .equipmentWidth img {
        height: 35px;
    }
}


@media screen and (max-width: 799px) {

    .equipment {
        margin-right: 0;
        margin-left: 0;
        width: 100%;
    }

    .equipment .equipmentWidth {
        width: 100%;
    }

    .equipment .equipmentWidth h2 {
        font-size: 30px;
        text-align: center;
        margin: 10px;
    }

    .equipment .equipmentWidth .equipmentCards {
        width: 750px;
        flex-direction: column;
    }

    .equipment .equipmentWidth .equipmentCard {
        width: 300px;
        height: 150px;
        margin: 10px;
    }

    .equipment .equipmentWidth .equipmentCardHeading {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: #014374;
        font-size: 18px;
        font-weight: 700;
        height: 40px;
        border-radius: 10px 10px 0px 0px;
        width: 100%;
    }

    .equipment .equipmentWidth .equipmentCardImage {
        height: 140px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px 0px 10px 10px;
    }

    .equipment .equipmentWidth img {
        height: 35px;
    }
}


@media screen and (max-width: 550px) {
    .equipment .equipmentWidth .equipmentCards {
        width: 100%;
        flex-direction: column;
    }

    .equipment .equipmentWidth h2 {
        font-size: 25px;
        text-align: center;
        margin: 10px;
    }
}