.contact-page {
    width: 100%;
    height: 1300px;
}

.contact-page .contact-container-holder {
    background-image: url("../../assets/contact/1.jpeg");
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    border-radius: 5px;
    width: 100%;
    justify-content: center;
    float: left;
    margin-right: 10%;
    height: 950px;
}

.contact-page .contact-container-holder .contact-container-image-hover {
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0;
}

.contact-page .contact-container-holder .contact-container-image-hover .contact-container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    width: 50%;
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 25%;
    justify-content: center;
    float: left;
}

.contact-page .map-container {
    width: 100%;
    height: 350px;
}

.contact-page .map-container iframe {
    width: 100%;
    height: 350px;
    border: none;
}


.contact-page .contact-container-holder .contact-container-image-hover .contact-container h1 {
    color: #474544;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 7px;
    text-align: center;
    text-transform: uppercase;
}

.contact-page .contact-container-holder .contact-container-image-hover .contact-container .underline {
    border-bottom: solid 2px #474544;
    margin: 10px auto;
    width: 200px;
}

.contact-page .contact-container-holder .contact-container-image-hover .contact-container .icon_wrapper {
    margin: 50px auto 0;
    width: 100%;
}

.contact-page .contact-container-holder .contact-container-image-hover .contact-container .icon {
    display: block;
    fill: #474544;
    height: 50px;
    margin: 0 auto;
    width: 50px;
}

.contact-page .contact-container-holder .contact-container-image-hover .contact-container input[type=text],
.contact-page .contact-container-holder .contact-container-image-hover .contact-container select,
.contact-page .contact-container-holder .contact-container-image-hover .contact-container textarea,
.contact-page .contact-container-holder .contact-container-image-hover .contact-container input[type=email] {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

.contact-page .contact-container-holder .contact-container-image-hover .contact-container input[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 18px 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    font-size: 22px;
}

.contact-page .contact-container-holder .contact-container-image-hover .contact-container input[type=submit]:hover {
    background-color: #45a049;
}

.contact-page .contact-container-holder .contact-container-image-hover .contact-container #message {
    line-height: 35px;
}

@media screen and (max-width: 900px) {
    .contact-container {
        width: 90%;
        margin-left: 5%;
    }

    .contact-page .contact-container-holder {
        margin-right: unset;
    }
}


@media screen and (max-width: 600px) {
    .contact-page {
        height: 1200px;
    }

    .contact-page .contact-container-holder {
        margin-right: unset;
        height: 850px;
    }

    .contact-page .contact-container-holder .contact-container-image-hover .contact-container {
        width: 90%;
        margin-left: 5%;
    }

    .contact-page .contact-container-holder .contact-container-image-hover input[type=submit] {
        font-size: 20px;
        padding: 13px 20px;
    }

    .contact-page .contact-container-holder .contact-container-image-hover h1 {
        font-size: 25px;
        letter-spacing: 3px;
    }
}