@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:200,300,400,500,600,700&display=swap');

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  /*background-color: #f7f4fd;*/
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}