/* SERVICES START */

.service-page .introduction {
    width: 100%;
    height: 600px;
    background-image: url("../../assets/2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    margin: 0;
}

.service-page .introduction .image-hover {
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0;
    float: left;
    display: flex;
    justify-content: center;
}

.service-page .introduction .image-hover .dots-bars-7 {
    color: #0076cd;
    margin-left: 45%;
    width: 80px;
    height: 80px;
}

.service-page .introduction .image-hover .text {
    color: white;
    margin: auto;
    font-size: 35px;
}

.service-page .introduction .image-hover .text h1 {
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 42px;
    text-transform: capitalize;
    margin: 20px auto auto;
}

.service-page .introduction-text-section {
    display: flex;
    justify-content: center;
    margin: auto;
    background-color: rgb(234 246 255);
    height: 300px;
}

.service-page .introduction-text-section .text {
    font-size: 25px;
    letter-spacing: 1px;
    font-family: 'Josefin Sans', sans-serif;
    text-align: justify;
    font-weight: 300;
    margin: auto;
    width: 60%;
}

@media only screen and (max-width: 1000px) {
    .services-container {
        height: 950px;
        margin-left: unset;
        margin-right: unset;
        padding-left: 3%;
        padding-right: 3%;
    }

    .services-container .single-service-box {
        width: 30%;
        margin-right: 3%;
        margin-bottom: 30px;
    }

}


@media only screen and (max-width: 800px) {
    .service-page .introduction-text-section {
        display: flex;
        justify-content: center;
        margin: auto;
        background-color: rgb(234 246 255);
        height: 300px;
    }

    .service-page .introduction-text-section .text {
        font-size: 20px;
        letter-spacing: 1px;
        font-family: 'Josefin Sans', sans-serif;
        text-align: justify;
        font-weight: 300;
        margin: auto;
        width: 70%;
    }

    .service-page .introduction .image-hover .text {
        color: white;
        margin: unset;
        margin-top: 30%;
        font-size: 35px;
    }

    .service-page .introduction .image-hover .dots-bars-7 {
        width: 70px;
        height: 70px;
        margin-left: 45%;
    }

    .service-page .introduction .image-hover .text h1 {
        text-align: center;
        font-size: 35px;
        margin-top: 40px;
    }
}


@media only screen and (max-width: 500px) {

    .service-page .introduction {
        height: 400px;
    }

    .service-page .introduction-text-section {
        display: flex;
        justify-content: center;
        margin: auto;
        background-color: rgb(234 246 255);
        height: 400px;
        padding: 40px 0;
    }

    .service-page .introduction {
        height: 400px;
    }

    .service-page .introduction .image-hover .text {
        color: white;
        margin: unset;
        margin-top: 30%;
        font-size: 35px;
    }

    .service-page .introduction .image-hover .dots-bars-7 {
        width: 70px;
        height: 70px;
        margin-left: 44%;
    }

    .service-page .introduction .image-hover .text h1 {
        font-family: 'Josefin Sans', sans-serif;
        text-align: center;
        margin-top: 40px;
        font-size: 25px;
        color: white;
        text-transform: capitalize;
    }
}


.service-page .services_heroImage {
    position: relative;
}

.service-page .services_heroImage img {
    width: 100%;
}

.service-page .services_heroImage p {
    position: absolute;
    color: yellow;
    text-align: center;
    top: 30%;
    left: 20%;
    font-size: 60px;
    font-weight: 700;
}


.service-page .services {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 70px;
}

.service-page .services .servicesWidth {
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-page .services .servicesWidth .main {
    width: 400px;
    margin-right: 20px;
}

.service-page .services .servicesWidth .main thead tr th {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 220px;
    filter: drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.2));
}

.service-page .services .servicesWidth .main .choosePackage {
    position: relative;
    background-color: #e3eaed;
    width: 400px;
    height: 150px;
    clip-path: polygon(
            80% 0,
            100% 51%,
            80% 100%,
            0 100%,
            0 0
    );
}

.service-page .services .servicesWidth .main p {
    color: #014374;
    position: absolute;
    font-size: 25px;
    font-weight: 700;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
}

.service-page .services .servicesWidth .main tbody {
    box-shadow: 3px 3px 14px rgba(145, 145, 145, 0.3);
}

.service-page .services .servicesWidth .main tr {
    height: 70px;
}

.service-page .services .servicesWidth .main td {
    padding-left: 40px;
    padding-right: 40px;
    color: #014374;
    font-size: 20px;
    font-weight: 600;
}


.service-page .services .servicesWidth .main tbody tr:nth-child(odd) {
    background-color: #e3eaed;
}

.service-page .services .servicesWidth .main tbody tr:nth-child(even) {
    background-color: #e6f4fb;
}


.service-page .services .standard {
    width: 250px;
    margin-right: 20px;
}

.service-page .services .standard thead tr th {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 220px;
    filter: drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.2));
}

.service-page .services .standard .standardPackage {
    position: relative;
    background-color: #014374;
    width: 250px;
    height: 200px;
    clip-path: polygon(
            100% 0,
            100% 80%,
            50% 100%,
            0 80%,
            0 0
    );
}

.service-page .services .standard .standardPackage .tableHeadingText {
    color: white;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.service-page .services .standard .standardPackage .tableHeadingTitle {
    font-size: 30px;
    font-weight: 700;
}

.service-page .services .standard .standardPackage .tableHeadingPercentage {
    font-size: 60px;
    font-weight: 700;
}

.service-page .services .standard .standardPackage .tableHeadingRevenue {
    min-width: 200px;
    font-size: 16px;
    font-weight: 600;
}

/*Table body */

.service-page .services tbody {
    box-shadow: 3px 3px 14px rgba(145, 145, 145, 0.3);
}

.service-page .services tr {
    height: 70px;
}

.service-page .services td {
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    color: #014374;
    font-size: 20px;
    font-weight: 600;
}

.service-page .services .fa-check {
    font-size: 35px;
    color: #329a37;
}

.service-page .services .fa-times {
    font-size: 35px;
    color: red;
}

.service-page .services tbody tr:nth-child(odd) {
    background-color: #f2f3f4;
}

.service-page .services tbody tr:nth-child(even) {
    background-color: #f6fbfe;
}

.service-page .services .pro {
    width: 250px;
}

.service-page .services thead tr th {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 220px;
    filter: drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.2));
}

.service-page .services .proPackage {
    position: relative;
    background-color: #efa105;
    width: 250px;
    height: 200px;
    clip-path: polygon(
            100% 0,
            100% 80%,
            50% 100%,
            0 80%,
            0 0
    );
}

.service-page .services .proPackage .tableHeadingText {
    color: white;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.service-page .services .proPackage .tableHeadingText .tableHeadingTitle {
    font-size: 30px;
    font-weight: 700;
    color: #004b68;
}

.service-page .services .proPackage .tableHeadingText .tableHeadingPercentage {
    font-size: 60px;
    font-weight: 700;
    color: #004b68;
}

.service-page .services .proPackage .tableHeadingText .tableHeadingRevenue {
    min-width: 200px;
    font-size: 16px;
    font-weight: 700;
    color: #004b68;
}

.service-page .services .standardPackage tbody {
    box-shadow: 3px 3px 14px rgba(145, 145, 145, 0.3);
}

.service-page .services tr {
    height: 70px;
}

.service-page .services td {
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    color: #014374;
    font-size: 20px;
    font-weight: 600;
}

.service-page .services .fa-check {
    font-size: 35px;
    color: #329a37;
}

.service-page .services tbody tr:nth-child(odd) {
    background-color: #f2f3f4;
}

.service-page .services tbody tr:nth-child(even) {
    background-color: #f6fbfe;
}

.service-page .equipments {
    background-color: rgb(234 246 255);
    height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.service-page .equipments .equipment-type {
    margin: auto;
    width: 60%;
}

.service-page .equipments .equipment-type h2 {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 37px;
    margin-bottom: 40px;
    margin-top: 40px;
    text-transform: capitalize;
}

.service-page .equipments .equipment-type .equipment-card {
    height: 200px;
    background-color: #ffffff;
    box-shadow: 0px 2px 9px rgba(145, 145, 145, 0.7);
    margin-bottom: 50px;
}

.service-page .equipments .equipment-type .equipment-card:hover {
    transform: scale(1.1);
    box-shadow: 0px 2px 9px rgba(145, 145, 145, 0.7);
}

.service-page .equipments .equipment-type .equipment-card .image-holder {
    padding: 10px;
    width: 30%;
    display: flex;
    justify-content: center;
    height: 100%;
    float: left;
}

.service-page .equipments .equipment-type .equipment-card .image-holder img {
    width: 70%;
    margin: 10px 0;
}

.service-page .equipments .equipment-type .equipment-card .text {
    width: 60%;
    float: left;
    padding: 0 10px;
    margin: 10px 0;
}

.service-page .equipments .equipment-type .equipment-card .text h3 {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-transform: uppercase;
}

.service-page .equipments .equipment-type .equipment-card .text p {
    font-size: 22px;
    letter-spacing: 1px;
    font-family: 'Josefin Sans', sans-serif;
    text-align: justify;
    font-weight: 300;
    margin: auto;
}

@media screen and (max-width: 1600px) {
    .service-page .services .services_heroImage {
        position: relative;
    }

    .service-page .services img {
        width: 100%;
    }

    /*.service-page .services p {*/
    /*    position: absolute;*/
    /*    color: yellow;*/
    /*    text-align: center;*/
    /*    top: 25%;*/
    /*    left: 20%;*/
    /*    font-size: 50px;*/
    /*    font-weight: 700;*/
    /*    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);*/
    /*}*/
}


@media screen and (max-width: 1300px) {
    .service-page .services .services_heroImage {
        position: relative;
    }

    .service-page .services img {
        width: 100%;
    }

    /*.service-page .services p {*/
    /*    position: absolute;*/
    /*    color: yellow;*/
    /*    text-align: center;*/
    /*    top: 20%;*/
    /*    left: 20%;*/
    /*    font-size: 50px;*/
    /*    font-weight: 700;*/
    /*}*/
}


@media screen and (max-width: 1100px) {
    .service-page .services {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 70px;
    }

    .service-page .services .servicesWidth {
        width: 1200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .service-page .services .main {
        width: 300px;
        margin-right: 20px;
    }

    .service-page .services thead tr th {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 220px;
        filter: drop-shadow(
                3px 3px 10px rgba(0, 0, 0, 0.2)
        );
    }

    .service-page .services .choosePackage {
        position: relative;
        background-color: #e3eaed;
        width: 300px;
        height: 150px;
        clip-path: polygon(
                80% 0,
                100% 51%,
                80% 100%,
                0 100%,
                0 0
        );
    }

    /*.service-page .services p {*/
    /*    color: #014374;*/
    /*    position: absolute;*/
    /*    font-size: 25px;*/
    /*    font-weight: 700;*/
    /*    top: 50%;*/
    /*    left: 40%;*/
    /*    transform: translate(-50%, -50%);*/
    /*}*/


    .service-page .services tbody {
        box-shadow: 3px 3px 14px rgba(145, 145, 145, 0.3);
    }

    .service-page .services td {
        padding-left: 40px;
        padding-right: 40px;
        color: yellow;
        font-size: 16px;
        font-weight: 600;
    }


    .service-page .services tbody tr:nth-child(odd) {
        background-color: #e3eaed;
    }

    .service-page .services tbody tr:nth-child(even) {
        background-color: #e6f4fb;
    }


    .service-page .services .standard {
        width: 200px;
        margin-right: 20px;
    }

    .service-page .services thead tr th {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 220px;
        filter: drop-shadow(
                3px 3px 10px rgba(0, 0, 0, 0.2)
        );
    }

    .service-page .services .standard .standardPackage {
        position: relative;
        background-color: #014374;
        width: 200px;
        height: 200px;
        clip-path: polygon(
                100% 0,
                100% 80%,
                50% 100%,
                0 80%,
                0 0
        );
    }

    .service-page .services .proPackage .tableHeadingText {
        color: white;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .service-page .services .proPackage .tableHeadingText .tableHeadingTitle {
        font-size: 30px;
        font-weight: 700;
    }

    .service-page .services .proPackage .tableHeadingText .tableHeadingPercentage {
        font-size: 60px;
        font-weight: 700;
    }

    .service-page .services .proPackage .tableHeadingText .tableHeadingRevenue {
        min-width: 163px;
        font-size: 16px;
        font-weight: 600;
    }

    .service-page .services tbody {
        box-shadow: 3px 3px 14px rgba(145, 145, 145, 0.3);
    }

    .service-page .services tr {
        height: 70px;
    }

    .service-page .services td {
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
        color: #014374;
        font-size: 20px;
        font-weight: 600;
    }

    .service-page .services .fa-check {
        font-size: 35px;
        color: #329a37;
    }

    .service-page .services .fa-times {
        font-size: 35px;
        color: red;
    }

    .service-page .services tbody tr:nth-child(odd) {
        background-color: #f2f3f4;
    }

    .service-page .services tbody tr:nth-child(even) {
        background-color: #f6fbfe;
    }


    .service-page .services .pro {
        width: 200px;
    }


    .service-page .services .standard thead tr th {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 220px;
        filter: drop-shadow(
                3px 3px 10px rgba(0, 0, 0, 0.2)
        );
    }

    .service-page .services .proPackage {
        position: relative;
        background-color: #efa105;
        width: 200px;
        height: 200px;
        clip-path: polygon(
                100% 0,
                100% 80%,
                50% 100%,
                0 80%,
                0 0
        );
    }

    .service-page .services .standard .standardPackage .tableHeadingText {
        color: #ffffff;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .service-page .services .proPackage .tableHeadingText .tableHeadingTitle {
        font-size: 30px;
        font-weight: 700;
    }

    .service-page .services .proPackage .tableHeadingText .tableHeadingPercentage {
        font-size: 60px;
        font-weight: 700;
    }

    .service-page .services .proPackage .tableHeadingText .tableHeadingRevenue {
        min-width: 163px;
        font-size: 16px;
        font-weight: 700;
    }

    .service-page .services tbody {
        box-shadow: 3px 3px 14px rgba(145, 145, 145, 0.3);
    }

    .service-page .services tr {
        height: 70px;
    }

    .service-page .services td {
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
        color: #014374;
        font-size: 20px;
        font-weight: 600;
    }

    .service-page .services .fa-check {
        font-size: 35px;
        color: #329a37;
    }

    .service-page .services tbody tr:nth-child(odd) {
        background-color: #f2f3f4;
    }

    .service-page .services tbody tr:nth-child(even) {
        background-color: #f6fbfe;
    }

    .service-page .equipments {
        height: 820px;
    }

    .service-page .equipments .equipment-type {
        width: 70%;
    }

    .service-page .equipments .equipment-type h2 {
        font-size: 30px;
    }

    .service-page .equipments .equipment-type .equipment-card {
        height: 330px;
        margin-bottom: 20px;
    }

    .service-page .equipments .equipment-type .equipment-card .image-holder {
        width: 100%;
        height: 130px;
    }

    .service-page .equipments .equipment-type .equipment-card .text {
        width: 100%;
        margin: auto;
    }

    .service-page .equipments .equipment-type .equipment-card .text h3 {
        font-size: 26px;
        width: 70%;
        margin-left: 15%;
        border-top: 1px solid #014374;
        padding-top: 15px;
    }

    .service-page .equipments .equipment-type .equipment-card .text p {
        font-size: 23px;
        padding: 0 30px;
    }
}

@media screen and (max-width: 799px) {
    .service-page .services .services_heroImage {
        position: relative;
    }

    .service-page .services img {
        width: 100%;
    }

    /*.service-page .services p {*/
    /*    position: absolute;*/
    /*    color: yellow;*/
    /*    text-align: center;*/
    /*    top: 10%;*/
    /*    left: 10%;*/
    /*    font-size: 40px;*/
    /*    font-weight: 700;*/
    /*}*/


    .service-page .services {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }

    .service-page .services .servicesWidth {
        overflow-x: scroll;
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;

        zoom: 70%;
        margin-left: 15%;
    }

    .service-page .services .main {
        width: 250px;
        margin-right: 20px;
    }

    .service-page .services thead tr th {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 220px;
        filter: drop-shadow(
                3px 3px 10px rgba(0, 0, 0, 0.2)
        );
    }

    .service-page .services .choosePackage {
        position: relative;
        background-color: #e3eaed;
        width: 250px;
        height: 150px;
        clip-path: polygon(
                80% 0,
                100% 51%,
                80% 100%,
                0 100%,
                0 0
        );
    }

    /*.service-page .services p {*/
    /*    color: #014374;*/
    /*    position: absolute;*/
    /*    font-size: 22px;*/
    /*    font-weight: 700;*/
    /*    top: 50%;*/
    /*    left: 40%;*/
    /*    transform: translate(-50%, -50%);*/
    /*}*/

    .service-page .services tbody {
        box-shadow: 3px 3px 14px rgba(145, 145, 145, 0.3);
    }

    .service-page .services tr {
        height: 70px;
    }

    .service-page .services td {
        padding-left: 40px;
        padding-right: 40px;
        color: #014374;
        font-size: 12px;
        font-weight: 600;
    }


    .service-page .services tbody tr:nth-child(odd) {
        background-color: #e3eaed;
    }

    .service-page .services tbody tr:nth-child(even) {
        background-color: #e6f4fb;
    }


    .service-page .services .standard {
        width: 170px;
        margin-right: 20px;
    }

    .service-page .services thead tr th {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 220px;
        filter: drop-shadow(
                3px 3px 10px rgba(0, 0, 0, 0.2)
        );
    }

    .service-page .services .standard .standardPackage {
        position: relative;
        background-color: #014374;
        width: 170px;
        height: 170px;
        clip-path: polygon(
                100% 0,
                100% 80%,
                50% 100%,
                0 80%,
                0 0
        );
    }

    .service-page .services .proPackage .tableHeadingText {
        color: white;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .service-page .services .proPackage .tableHeadingText .tableHeadingTitle {
        font-size: 20px;
        font-weight: 700;
    }

    .service-page .services .proPackage .tableHeadingText .tableHeadingPercentage {
        font-size: 50px;
        font-weight: 700;
    }

    .service-page .services .proPackage .tableHeadingText .tableHeadingRevenue {
        min-width: 163px;
        font-size: 16px;
        font-weight: 600;
    }

    .service-page .services tbody {
        box-shadow: 3px 3px 14px rgba(145, 145, 145, 0.3);
    }

    .service-page .services tr {
        height: 70px;
    }

    .service-page .services td {
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
        color: #014374;
        font-size: 20px;
        font-weight: 600;
    }

    .service-page .services .fa-check {
        font-size: 35px;
        color: #329a37;
    }

    .service-page .services .fa-times {
        font-size: 35px;
        color: red;
    }

    .service-page .services tbody tr:nth-child(odd) {
        background-color: #f2f3f4;
    }

    .service-page .services tbody tr:nth-child(even) {
        background-color: #f6fbfe;
    }


    .service-page .services .pro {
        width: 170px;
    }

    .service-page .services .standard thead tr th {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 220px;
        filter: drop-shadow(
                3px 3px 10px rgba(0, 0, 0, 0.2)
        );
    }

    .service-page .services .proPackage {
        position: relative;
        background-color: #efa105;
        width: 170px;
        height: 170px;
        clip-path: polygon(
                100% 0,
                100% 80%,
                50% 100%,
                0 80%,
                0 0
        );
    }

    .service-page .services .standard .standardPackage .tableHeadingText {
        color: #ffffff;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .service-page .services .standard .standardPackage .tableHeadingTitle {
        font-size: 20px;
        font-weight: 700;
    }

    .service-page .services .standard .standardPackage .tableHeadingPercentage {
        font-size: 50px;
        font-weight: 700;
    }

    .service-page .services .standard .standardPackage .tableHeadingRevenue {
        min-width: 163px;
        font-size: 16px;
        font-weight: 700;
    }

    .service-page .services tbody {
        box-shadow: 3px 3px 14px rgba(145, 145, 145, 0.3);

    }

    .service-page .services tr {
        height: 70px;
    }

    .service-page .services td {
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
        color: #014374;
        font-size: 20px;
        font-weight: 600;
    }

    .service-page .services .fa-check {
        font-size: 35px;
        color: #329a37;
    }

    .service-page .services tbody tr:nth-child(odd) {
        background-color: #f2f3f4;
    }

    .service-page .services tbody tr:nth-child(even) {
        background-color: #f6fbfe;
    }

    .service-page .equipments {
        height: 820px;
    }

    .service-page .equipments .equipment-type {
        width: 85%;
    }

    .service-page .equipments .equipment-type h2 {
        font-size: 25px;
    }

    .service-page .equipments .equipment-type .equipment-card {
        height: 330px;
        margin-bottom: 20px;
    }

    .service-page .equipments .equipment-type .equipment-card .image-holder {
        width: 100%;
        height: 130px;
    }

    .service-page .equipments .equipment-type .equipment-card .text {
        width: 100%;
        margin: auto;
    }

    .service-page .equipments .equipment-type .equipment-card .text h3 {
        font-size: 20px;
        width: 70%;
        margin-left: 15%;
        border-top: 1px solid #014374;
        padding-top: 15px;
    }

    .service-page .equipments .equipment-type .equipment-card .text p {
        font-size: 17px;
        padding: 0 30px;
    }
}

/*@media screen and (max-width: 480px) {*/
/*    .service-page .services .services_heroImage {*/
/*        position: relative;*/
/*    }*/

/*    .service-page .services img {*/
/*        width: 100%;*/
/*    }*/

/*    .service-page .services p {*/
/*        position: absolute;*/
/*        color: yellow;*/
/*        text-align: center;*/
/*        top: 10%;*/
/*        left: 10%;*/
/*        font-size: 30px;*/
/*        font-weight: 700;*/
/*    }*/
/*}*/

/* SERVICES END */
