.home .service-highlight-1 {
    width: 100%;
    height: 400px;
    background-image: url("../../../../assets/2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    padding: 0;
    margin: 0;
}

.home .service-highlight-1 .image-hover {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    float: left;
    display: flex;
    align-items: center;
}

.home .service-highlight-1 .image-hover .column {
    width: 50%;
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home .service-highlight-1 .image-hover .column .heading {
    width: 50%;
}

.home .service-highlight-1 .image-hover .column .heading h2 {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 42px;
    margin-top: 30px;
    text-transform: capitalize;
    color: white;
}

.home .service-highlight-1 .image-hover .column .heading .dots-bars-7 {
    color: #0076cd;
    margin-left: 50%;
}

.home .service-highlight-1 .image-hover .column .description {
    width: 80%;
}

.home .service-highlight-1 .image-hover .column .description .bar {
    background-color: #0076cd;
    width: 70%;
    height: 8px;
    margin-left: 30px;
}

.home .service-highlight-1 .image-hover .column .description .text {
    padding: 30px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
    font-weight: 300;
    text-align: justify;
    color: white;
}

@media only screen and (max-width: 950px) {
    .home .service-highlight-1 .image-hover .column .heading {
        width: 80%;
        font-size: 25px;
    }

    .home .service-highlight-1 .image-hover .column .description {
        width: 100%;
        margin: unset;
    }

    .home .service-highlight-1 .image-hover .column .description .text {
        font-size: 22px;
    }
}

@media only screen and (max-width: 760px) {
    .home .service-highlight-1 {
        width: 100%;
        height: 450px;
        background-image: url("../../../../assets/2.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        margin: 0;
    }

    .home .service-highlight-1 .image-hover {
        display: block;
    }

    .home .service-highlight-1 .image-hover .column {
        width: 100%;
        float: left;
        display: block;
        height: 40%;
    }

    .home .service-highlight-1 .image-hover .column .heading {
        width: 100%;
    }

    .home .service-highlight-1 .image-hover .column .heading h2 {
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 25px;
        margin-top: 30px;
        text-transform: capitalize;
        color: white;
    }

    .home .service-highlight-1 .image-hover .column .heading .dots-bars-7 {
        color: #0076cd;
        margin-left: 45%;
        width: 50px;
        height: 50px;
        margin-top: 50px;
    }

    .home .service-highlight-1 .image-hover .column .description {
        width: 80%;
        margin-left: 10%;
    }

    .home .service-highlight-1 .image-hover .column .description .bar {
        background-color: #0076cd;
        width: 50%;
        height: 8px;
    }

    .home .service-highlight-1 .image-hover .column .description .text {
        padding: 30px;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 25px;
        font-weight: 300;
        text-align: justify;
        color: white;
    }
}


@media only screen and (max-width: 530px) {
    .home .service-highlight-1 {
        width: 100%;
        height: 550px;
        background-image: url("../../../../assets/2.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        margin: 0;
    }


    .home .service-highlight-1 .image-hover .column .description {
        width: 100%;
        margin-left: unset;
    }
}