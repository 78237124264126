
.services-container {
    width:100%;
    padding-left: 15%;
    padding-right: 15%;
    display: block;
    padding-top: 30px;
    min-height: 850px;
    background-color: rgb(234 246 255);
}

.services-container .single-service-box {
    border: 5px solid #f7f7f7;
    padding: 5px;
    border-radius: 10px;
    width: 30%;
    float: left;
    height: 300px;
    margin-right: 3%;
    margin-bottom: 30px;
    -moz-box-shadow: 0 0 10px 5px #c8c8c8;
    -webkit-box-shadow: 0 0 10px 5px #c8c8c8;
    box-shadow: 0 0 10px 5px #c8c8c8;
    background-color: white;
}

.services-container .single-service-box {
    position: relative;
    display: block;
    transition: .5s;
}

.services-container .single-service-box::before {
    content: '';
    position: absolute;
    background: #014374;
    transition: .5s;
    transform: scale(.9);
    z-index: -1;
}

.services-container .single-service-box:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #014374;
}

.services-container .single-service-box:hover {
    box-shadow: 0 0 15px #014374;
    transform: scale(1.1);
}

.services-container .single-service-box .single-service {
    background: #f1f1f1;
    padding: 30px 20px;
    border-radius: 10px;
    height: 100%;
}

.services-container .single-service-box .single-service .services-icon {
    border: 5px solid #ffffff;
    padding: 15px;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 20px;
    color: #0076cd;
    background-color: #f1f1f1;
    margin-left: 36%;
}

.services-container .single-service-box .single-service .services-text h3 {
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
    color: #333;
    text-transform: capitalize;
}

.services-container .single-service-box .single-service .services-text p {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 350;
    letter-spacing: 1px;
    font-family: 'Josefin Sans', sans-serif;
    color: #333;
}

.section-introduction {
    padding: 0 20px;
    margin: 30px 0;
}

.section-introduction h2 {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 37px;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.section-introduction p {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 21px;
    font-weight: 300;
}

.services-container button {
    margin-left: 43%;
    margin-top: 20px;
}

@media only screen and (max-width: 1300px) {
    .services-container .single-service-box {
        height: 380px;
    }

    .services-container .single-service-box .single-service .services-icon {
        margin-left: 33%;
    }

    .services-container button {
        margin-left: 40%;
    }

    .services-container {
        padding-left: 9%;
        padding-right: 9%;
        min-height: 850px;
    }
}

@media only screen and (max-width: 950px) {
    .services-container {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .services-container .single-service-box {
        height: 380px;
        width: 33%;
        margin: 0;
    }
}

@media only screen and (max-width: 760px) {

    .services-container .single-service-box .single-service .services-icon {
        margin-left: 42%;
    }

    .services-container {
        width: 100%;
        margin: 0;
        padding-left: 8%;
        padding-right: 8%;
        min-height: 2500px;
    }

    .services-container .single-service-box {
        height: 320px;
        width: 100%!important;
        margin: 0;
        margin-bottom: 30px;
    }

    .services-container button {
        margin-left: 32%;
        margin-top: 0;
    }
}

@media only screen and (max-width: 600px) {

    .services-container .single-service-box .single-service .services-icon {
        margin-left: 39%;
    }

    .services-container {
        min-height: 2300px;
    }
}

@media only screen and (max-width: 500px) {

    .services-container .single-service-box .single-service .services-icon {
        margin-left: 35%;
    }
}