/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.top-header-top {
    width: 100%;
    background-color: #014374;
    content: "";
    clear: both;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 3;
}

.top-header-top .column {
    float: left;
    width: 50%;
    font-size: 15px;
    padding: 5px 0;
}

.top-header-top .column #welcome {
    margin-left: 30%;
}

.top-header-top .column #phone {
    margin-left: 50px;
}

.top-header-top .column a {
    color: #ffffff;
}

.top-header-top .column a:link {
    text-decoration: none;
}

.top-header-top .column a:visited {
    text-decoration: none;
}

.top-header-top .column a:hover {
    text-decoration: underline;
}

.top-header-top .column a:active {
    text-decoration: underline;
}

header {
    padding: 0 10%;
    background-color: #ffffff;
    height: 70px;
    display: flex;
    justify-content: space-between;
    z-index: 3;
    position: relative;
}

header a {
    text-decoration: none;
}

#brand {
    font-weight: bold;
    display: flex;
    align-items: center;
    z-index: 2;
    width: 300px;
    height: 70px;
    margin-top: 0;
    margin-bottom: 0;
}

header ul {
    list-style: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

header ul a {
    color: #000000;
    font-size: 25px;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    padding: 5px;
}

header > nav > ul > li > a.active {
    color: #2495ED;
}

header ul li .nav-link:hover {
    background-color: #C7E3F8;
    color: #2495ED;
    border-radius: 5px;
    padding: 0 10px;
}

ul li {
    margin-left: 10px;
}

ul li:hover {
    transform: scale(1.1);
    transition: 0.3s;
}

#carrier-setup {
    border-radius: 10px;
    padding: 5px 8px;
    background-color: #0076cd;
    color: white;
}

#carrier-setup:hover {
    padding-left:  10px;
    padding-right: 10px;
    border-radius: 10px;
}

#carrier-setup a {
    color: white;
}

#mobile-menu-container {
    margin: auto 0;
    display: none;
    cursor: pointer;
}

#mobile-menu-container .bar1, #mobile-menu-container .bar2, #mobile-menu-container .bar3 {
    width: 27px;
    height: 3px;
    background-color: #000000;
    margin: 6px 0;
    transition: 0.4s;
}

.open .bar1 {
    color: black;
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}

.open .bar2 {
    opacity: 0;
}

.open .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -8px);
    transform: rotate(45deg) translate(-6px, -8px);
}

.open .mobile-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    height: 300px;
}

.mobile-menu {
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    height: calc(100vh - 50px);
    width: 100%;
    background-color: white;
}

#mobile-menu-container .mobile-menu .nav-link {
    width: 97%;
    border: 1px solid rgba(255,255,255,1) !important;
    background-color: #0076cd;
    border-radius: 5px;
    margin-left: 1%;
    margin-right: 1%;
    padding: 5px;
    color: white;
}
@media only screen and (max-width: 850px) {

    header {
        padding-left: 0;
    }

    header nav {
        display: none;
    }

    #mobile-menu-container {
        display: block;
    }

    .top-header-top .column {
        font-size: 13px;
    }

    .top-header-top .left {
        display: none;
    }

    .top-header-top .right {
        width: 100%;
        padding-left: 10%;
    }

    header ul a {
        font-size: 22px;
    }

    #mobile-menu-container .mobile-menu hr {
        width: 98%;
        bottom: 50px;
        position: absolute;
        z-index: 3;
        box-shadow: 0 -2px 1px #0076cd;
        color: white;
    }

    #mobile-menu-container .mobile-menu #carrier-setup {
        margin-top: 10%;
        position: absolute;
        bottom: 0;
        margin-bottom: 5px;
        color: white;
        width: 97%;
        margin-left: 1%;
        margin-right: 1%;
        border-radius: 5px;
    }

    #carrier-setup a {
        color: white;
    }

    #mobile-menu-container .mobile-menu ul li .active {
        color: #ffffff!important;
    }
}
