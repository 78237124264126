.uniqueness-container {
    min-height: 640px;
}

.uniqueness {
    height: 285px;
}

.uniqueness .content {
    padding: 0 10%;
    float: left;
    width: 55%;

}

.uniqueness div .title {
    margin-top: 60px;
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
    font-family: 'Courier New', monospace, sans-serif;
    background-color: #0076cd;
    padding: 10px;
    color: white;
    border-radius: 10px;
}

.uniqueness div .description {
    margin: 0 30px;
    width: 90%;
    margin-top: 20px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 21px;
    font-weight: 300;
    text-align: justify;
}

.uniqueness div .description h2 {
    text-transform: capitalize;
    font-size: 23px;
    color: #0076cd;
    margin-bottom: 10px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
}

.uniqueness div .description h3 {
    margin-top: 10px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
}

.uniqueness div .description h3 a {
    color: #0076cd;
    margin-top: 10px;
}

.uniqueness .image {
    margin-top: 13px;
    float: left;
    width: 37%;
}

.uniqueness .image img {
    width: 90%;
}

@media screen and (max-width: 1450px) {
    .uniqueness-container {
        height: 780px;
    }
}

@media screen and (max-width: 1320px) {
    .uniqueness-container {
        height: 730px;
    }
}


@media only screen and (max-width: 1250px) {
    .uniqueness-container {
        height: 730px;
    }
}

@media only screen and (max-width: 1000px) {

    .uniqueness-container {
        height: 1300px;
    }

    .uniqueness {
        width: 100%;
        height: 800px;
    }

    .uniqueness-container .one {
        height: 550px;
    }

    .uniqueness .content {
        width: 100%;
    }

    .uniqueness .image {
        width: 100%;
        margin-top: 30px;
        margin-left: 10%;
    }

    .uniqueness .image img {
        width: 80%;
    }
}

@media only screen and (max-width: 760px) {
    .uniqueness-container .one {
        height: 450px;
    }

    .uniqueness {
        height: 680px;
    }

    .uniqueness div .title {
        font-size: 14px;
    }

    .uniqueness div .description {
        margin: 0;
        font-size: 19px;
        margin-top: 15px;
        width: 100%;
    }

    .uniqueness div .description h2 {
        font-size: 19px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .uniqueness div .description h3 {
        font-size: 17px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 650px) {

    .uniqueness-container {
        height: 1000px;
    }

    .uniqueness {
        height: 650px;
    }

    .uniqueness .image {
        margin-left: unset;
    }

    .uniqueness .image img {
        width: 80%;
        margin-left: 10%;
    }

    .uniqueness-container .one  {
        height: 400px;
    }

    .uniqueness-container .two  {
        height: 600px;
    }

    .uniqueness div .title {
        font-size: 13px;
    }

    .uniqueness div .description {
        margin: 0;
        font-size: 18px;
        margin-top: 15px;
        width: 100%;
    }

    .uniqueness div .description h2 {
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .uniqueness div .description h3 {
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}