
.introduction {
    width: 100%;
    height: 600px;
    background-image: url("../../../assets/4.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    margin: 0;
}

.introduction .image-hover {
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    float: left;
}

.introduction .image-hover .introduction-box {
    width: 50%;
    margin-left: 20%;
    margin-top: 23%;
}

.introduction .image-hover .introduction-box .title {
    font-size: 27px;
    color: white;
    font-family: 'Courier New', monospace, sans-serif;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.introduction .image-hover .modal-video {
    background-color: white;
    width: 900px;
}

@media only screen and (max-width: 1100px) {
    .introduction .image-hover .introduction-box {
        width: 70%;
        margin-left: 10%;
        margin-top: 45%;
    }
}

@media only screen and (max-width: 750px) {

    .introduction .image-hover .introduction-box {
        width: 70%;
        margin-left: 10%;
        margin-top: 60%;
    }
}

@media only screen and (max-width: 500px) {
    .introduction .image-hover .introduction-box {
        margin-top: 90%;
        width: 90%;
        margin-left: 5%;
    }

    .introduction {
        width: 100%;
        height: 550px;
    }
}

