@import url(https://fonts.googleapis.com/css?family=Khula:700);

.hidden {
    opacity:0;
}

.console-container {

    font-family:Khula;
    font-size:45px;
    text-align:center;
    height:200px;
    width:90%;
    display:block;
    position:absolute;
    color:white;
    margin:auto;
    margin-top: 18%;
    padding-left: 5%;
}

.console-underscore {
    display:inline-block;
    position:relative;
    top:-0.14em;
    left:10px;
}

@media only screen and (max-width: 1100px) {
    .console-container {
        font-size:35px;
        height:100px;
        width:100%;
        margin-top: 30%;
        padding-left: 2%;
    }
}

@media only screen and (max-width: 750px) {
    .console-container {
        font-size:35px;
        height:100px;
        width:100%;
        margin-top: 50%;
        padding-left: 2%;
    }
}

@media only screen and (max-width: 500px) {
    .console-container {

        font-size:30px;
        text-align:center;
        height:100px;
        width:100%;
        display:block;
        position:absolute;
        color:white;
        margin:auto;
        margin-top: 65%;
        padding-left: 2%;
    }
}