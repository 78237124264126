
/* Footer Start */

footer {
    background-color: #014374;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
}

footer .footerWidth {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px;
    margin-top: 50px;
}

footer .footerWidth .footerLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

footer .footerWidth img {
    width: 250px;
    margin: 15px;
}

footer .footerWidth .socialLinks {
    display: flex;
    justify-content: center;
    align-items: center;
}

footer .footerWidth a {
    text-decoration: none;
    color: white;
}

footer .footerWidth i {
    font-size: 30px;
    padding: 15px;
}

footer .footerWidth a:hover {
    color: #ffbe00;
}

footer .footerWidth .footerCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

footer .footerWidth p {
    color: white;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
}

footer .footerWidth .iSpan {
    display: inline-block;
    min-width: 60px;
    text-align: center;
}

footer .footerWidth .svg-inline--fa {
    margin-right: 10px;
}

footer .footerWidth .footerRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

footer .footerWidth p {
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
}


@media screen and (max-width: 1340px) {
    footer .footerWidth .footerLeft img {
        width: 200px;
        margin: 15px;
    }

    footer .footerWidth .socialLinks {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    footer .footerWidth a {
        text-decoration: none;
        color: white;
    }

    footer .footerWidth .svg-inline--fa {
        margin-right: 16px;
    }

    footer .footerWidth a:hover {
        color: #ffbe00;
    }

    footer .footerWidth .footerCenter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    footer .footerWidth p {
        color: white;
        padding: 10px;
        font-size: 16px;
        font-weight: 600;
    }

    footer .footerWidth .iSpan {
        display: inline-block;
        min-width: 60px;
        text-align: center;
    }

    footer .footerWidth .footerRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    footer .footerWidth p {
        color: white;
        font-size: 16px;
        font-weight: 600;
        padding: 10px;
    }
}

@media screen and (max-width: 1000px) {
    footer {
        height: 100%;
    }

    .footerWidth {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0px;
    }

    footer .footerWidth .footerLeft {
        margin: 30px;
    }

    footer .footerWidth img {
        width: 200px;
        margin: 15px;
    }

    footer .footerWidth .socialLinks {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    footer .footerWidth a {
        text-decoration: none;
        color: white;
    }

    footer .footerWidth a:hover {
        color: #ffbe00;
    }
}


footer .footerWidth .footerCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

footer .footerWidth p {
    color: white;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
}

footer .footerWidth .iSpan {
    display: inline-block;
    min-width: 60px;
    text-align: center;
}


footer .footerWidth .footerRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px;
}

footer .footerWidth p {
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
}


@media screen and (max-width: 500px) {
    footer {
        height: 100%;
    }

    .footerWidth {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    footer .footerWidth .footerLeft {
        margin: 30px;
    }

    footer .footerWidth img {
        width: 200px;
        margin: 15px;
    }

    footer .footerWidth .socialLinks {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    footer .footerWidth a {
        text-decoration: none;
        color: white;
    }


    footer .footerWidth a:hover {
        color: #014374;
    }

    footer .footerWidth .footerCenter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    footer .footerWidth p {
        color: white;
        padding: 10px;
        font-size: 13px;
        font-weight: 600;
    }

    footer .footerWidth .iSpan {
        display: inline-block;
        min-width: 60px;
        text-align: center;
    }

    footer .footerWidth .footerRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px;
    }

    footer .footerWidth p {
        color: white;
        font-size: 14px;
        font-weight: 600;
        padding: 10px;
    }
}


/* Footer End */
