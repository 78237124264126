.info-container {
    min-height: 400px;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    display: block;
    padding-top: 30px;
    background-color: rgb(234 246 255);
}

.info-container .box {
    width: 25%;
    float: left;
    position: relative;
    top: -80px;
    margin-right: 2%;
}

.info-container .box .ReactFlipCard {
    width: 100%;
    height: 350px;
}

.info-container .ReactFlipCard .ReactFlipCard__Front, .ReactFlipCard .ReactFlipCard__Back {
    background-color: white;
    height: 350px;
    width: 100%;
}

.info-container .ReactFlipCard .ReactFlipCard__Back {
    background-color: #0076cd;
    color: white;
}

.info-container .ReactFlipCard .ReactFlipCard__Back .description {
    display: flex;
    justify-content: center;
}

.info-container .ReactFlipCard .ReactFlipCard__Back .description a {
    display: block;
    height: 45px;
}

.info-container .box .title {
    background-color: #0076cd;
    line-height: 50px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    color: white;
}

.info-container .box .title h3 {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
    text-transform: capitalize;
    color: white;
}

.ReactFlipCard .ReactFlipCard__Front .description {
    -moz-box-shadow: 0 5px 5px 2px #e5e5e5;
    -webkit-box-shadow: 0 5px 5px 2px #e5e5e5;
    box-shadow: 0 5px 5px 2px #e5e5e5;
}

.second .ReactFlipCard .ReactFlipCard__Front .description {
    box-shadow: unset;
    -moz-box-shadow: unset;
    -webkit-box-shadow: unset;
    border-top: 1px solid white;
}

.second .ReactFlipCard .ReactFlipCard__Front,
.second .ReactFlipCard .ReactFlipCard__Back {
    background-color: #0076cd;
    color: white;
    height: 220px;
}

.second .ReactFlipCard .ReactFlipCard__Front h3 {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: 'Josefin Sans', sans-serif;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.info-container .ReactFlipCard .description {
    font-size: 19px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 350;
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 280px;
    border-top: 1px solid white;
}

.info-container .section-introduction {
    padding: 0 20px;
    margin: 30px 0;
    width: 46%;
    float: left;
}

.info-container .section-introduction h2 {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 37px;
    margin-bottom: 20px;
    color: #0076cd;
}

.info-container .section-introduction p {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 21px;
    font-weight: 300;
    text-align: justify;
}

.info-container  .services-container button {
    margin-left: 43%;
    margin-top: 20px;
}

@media screen and (max-width: 1500px) {
    .info-container {
        height: 470px;
    }
}


@media screen and (max-width: 1320px) {
    .info-container {
        height: 500px;
    }
}

@media only screen and (max-width: 1000px) {
    .info-container {
        min-height: 200px;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        height: 1100px;
    }

    .info-container .box {
        width: 100%;
        top: -80px;
        margin-bottom: 30px;
    }

    .info-container .ReactFlipCard .description {
        font-size: 14px;
        padding-right: 15px;
    }

    .info-container .section-introduction {
        width: 100%;
    }

    .info-container .second .ReactFlipCard {
        height: 200px;
    }
}

@media only screen and (max-width: 650px) {
    .info-container {
        width: 100%;
        margin: 0;
        padding: 0 5%;
        text-align: justify;
        height: 1150px;
    }

    .info-container .section-introduction h2 {
        font-size: 24px;
    }

    .info-container .section-introduction p {
        font-size: 18px;
    }

    .info-container .box {
        width: 100%;
        top: -50px;
        margin-bottom: 30px;
    }

    .info-container .ReactFlipCard .description {
        font-size: 14px;
        padding-right: 15px;
    }

    .info-container .section-introduction {
        width: 100%;
    }

    .info-container .second .ReactFlipCard {
        height: 200px;
    }

    .info-container .box {
        height: 300px;
    }

    .info-container .box .ReactFlipCard {
        height: 300px;
    }

    .info-container .ReactFlipCard .ReactFlipCard__Front, .ReactFlipCard .ReactFlipCard__Back {
        height: 300px;
    }
}