.about-page {
    width: 100%;
    height: 1200px;
}

.about-page .introduction {
    background-image: url("../../assets/about/1.jpeg");
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    border-radius: 5px;
    width: 100%;
    justify-content: center;
    float: left;
    margin-right: 10%;
    height: 600px;
}

.about-page .introduction .image-holder {
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0;
    display: flex;
    justify-content: center;
}

.about-page .introduction .image-holder .text {
    margin: auto;
    display: flex;
    justify-content: center;
}

.about-page .introduction .image-holder .text h1 {
    margin-left: 30px;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 42px;
    text-transform: capitalize;
}

.about-page .about-ceo {
    width: 100%;
    height: 600px;
    background-color: rgb(234 246 255);;
    float: left;
    display: flex;
    justify-content: center;
}

.about-page .about-ceo > div {
    width: 75%;
    height: 450px;
    margin: auto;
    padding-top: 20px;
}

.about-page .about-ceo > div > .image {
    width: 30%;
    border-radius: 50%;
    float: left;
    text-align: center;
    height: 100%;
}

.about-page .about-ceo div .image .image-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
}

.about-page .about-ceo > div > .image .border-line-one {
    border: 1px solid #0076cd;
    width: 360px;
    height: 360px;
    border-radius: 50%;
    padding: 4px;
}

.about-page .about-ceo > div > .image .border-line-one .border-line-two {
    padding: 25px;
    border: 1px solid #0076cd;
    border-radius: 50%;
    width: 350px;
    height: 350px;
}

.about-page .about-ceo > div > .image img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin: auto;
}

.about-page .about-ceo > div > .image h3 {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 28px;
    text-transform: capitalize;
    font-weight: 550;
    margin-top: 15px;
}

.about-page .about-ceo div .image h4 {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
    text-transform: capitalize;
    font-weight: 400;
}

.about-page .about-ceo div .text {
    width: 70%;
    float: left;
    height: 100%;
}

.about-page .about-ceo > div > .text .heading {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
}

.about-page .about-ceo > div > .text .heading h3 {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 28px;
    text-transform: capitalize;
    font-weight: 550;
    margin: auto;
}

.about-page .about-ceo > div > .text .description {
    width: 96%;
    margin-left: 2%;
    height: 85%;
    text-align: justify;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 22px;
    font-weight: 350;
    line-height: 25px;
}

.about-values {
    background-color: rgb(234 246 255);
    height: 530px;
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    display: block;
    padding-top: 30px;
}

.about-values h2 {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 42px;
    text-transform: capitalize;
    padding: 20px 0;
}


.about-values .single-about-box {
    border: 5px solid #f7f7f7;
    padding: 5px;
    border-radius: 10px;
    width: 30%;
    float: left;
    height: 340px;
    margin-right: 3%;
    margin-bottom: 30px;
    -moz-box-shadow: 0 0 10px 5px #c8c8c8;
    -webkit-box-shadow: 0 0 10px 5px #c8c8c8;
    box-shadow: 0 0 10px 5px #c8c8c8;
    background-color: white;
    position: relative;
    display: block;
    transition: .5s;
}

.about-values .single-about-box::before {
    content: '';
    position: absolute;
    background: #014374;
    transition: .5s;
    transform: scale(.9);
    z-index: -1;
}

.about-values .single-about-box:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #014374;
}

.about-values .single-about-box:hover {
    box-shadow: 0 0 15px #014374;
    transform: scale(1.1);
}

.about-values .single-about-box .single-service {
    background: #f1f1f1;
    padding: 30px 20px;
    border-radius: 10px;
    height: 100%;
}

.about-values .single-about-box .single-service .services-icon {
    border: 5px solid #ffffff;
    padding: 15px;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 20px;
    color: #0076cd;
    background-color: #f1f1f1;
    margin-left: 36%;
}

.about-values .single-about-box .single-service .services-text h3 {
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
    color: #333;
    text-transform: capitalize;
}

.about-values .single-about-box .single-service .services-text p {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 350;
    letter-spacing: 1px;
    font-family: 'Josefin Sans', sans-serif;
    color: #333;
    text-align: justify;
}

@media only screen and (max-width: 1450px) {
    .about-page .about-ceo > div {
        width: 95%;
    }

    .about-page .about-ceo > div > .text .description {
        font-size: 21px;
    }

    .about-values {
        height: 550px;
        padding-left: 9%;
        padding-right: 9%;
    }

    .about-values .single-about-box {
        height: 350px;
    }
}


@media only screen and (max-width: 1300px) {

    .about-values {
        height: 550px;
        padding-left: 7%;
        padding-right: 7%;
    }

    .about-values .single-about-box {
        height: 380px;
    }

    .about-values .single-about-box .single-service .services-icon {
        margin-left: 33%;
    }

    .services-container button {
        margin-left: 40%;
    }

    .services-container {
        padding-left: 9%;
        padding-right: 9%;
        min-height: 850px;
    }

    .about-page .about-ceo > div {
        width: 90%;
        height: 600px;
    }

    .about-page .about-ceo > div > .text .description {
        font-size: 20px;
    }

    .about-page .about-ceo > div > .image .border-line-one {
        border: 1px solid #0076cd;
        width: 310px;
        height: 310px;
        border-radius: 50%;
        padding: 4px;
    }

    .about-page .about-ceo > div > .image .border-line-one .border-line-two {
        padding: 20px;
        border: 1px solid #0076cd;
        border-radius: 50%;
        width: 300px;
        height: 300px;
    }

    .about-page .about-ceo > div > .image img {
        width: 260px;
        height: 260px;
    }
}

@media only screen and (max-width: 950px) {

    .about-page {
        height: 1450px;
    }

    .about-values .single-about-box {
        height: 380px;
        width: 33%;
        margin: 0;
    }

    .about-values {
        height: 600px;
        padding-left: 4%;
        padding-right: 4%;
    }

    .about-page .about-ceo {
        height: 850px;
    }

    .about-page .about-ceo > div {
        width: 96%;
        padding-top: 10px;
        height: 800px;
    }

    .about-page .about-ceo > div > .image {
        width: 100%;
        height: unset;
        margin-left: unset;
    }

    .about-page .about-ceo > div > .image .border-line-one {
        padding: 4px;
        border: 1px solid #0076cd;
        border-radius: 50%;
        width: 260px;
        height: 260px;
    }

    .about-page .about-ceo > div > .image .border-line-one .border-line-two {
        padding: 25px;
        border: 1px solid #0076cd;
        border-radius: 50%;
        width: 250px;
        height: 250px;
    }

    .about-page .about-ceo > div > .image img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin: auto;
    }

    .about-page .about-ceo div .image h3 {
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 22px;
        text-transform: capitalize;
        font-weight: 550;
    }

    .about-page .about-ceo div .image h4 {
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 22px;
        text-transform: capitalize;
        font-weight: 400;
    }

    .about-page .about-ceo div .text {
        width: 90%;
        float: left;
        height: unset;
        margin-left: 5%;
        margin-top: 30px;
    }

    .about-page .about-ceo > div > .text .heading {
        width: 100%;
        height: unset;
        display: flex;
        justify-content: center;
    }

    .about-page .about-ceo > div > .text .heading h3 {
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 22px;
        text-transform: capitalize;
        font-weight: 550;
        margin: auto;
    }

    .about-page .about-ceo > div > .text .description {
        width: 96%;
        margin-left: 2%;
        height: 85%;
        text-align: justify;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 17px;
        font-weight: 350;
        line-height: 25px;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 760px) {

    .about-values .single-about-box .single-service .services-icon {
        margin-left: 42%;
    }

    .about-page {
        height: 1350px;
    }

    .about-values .single-about-box {
        height: 320px;
        width: 100%;
        margin: 0;
        margin-bottom: 30px;
    }

    .about-page .introduction {
        height: 400px;
        margin-right: unset;
    }

    .about-page .introduction .image-holder .text {
        padding: 0 5%;
        display: block;
    }

    .about-page .introduction .image-holder .text h1 {
        font-size: 30px;
    }

    .about-page .introduction .image-holder .text .square-to-circle-animation {
        margin-left: 46%;
        margin-bottom: 30px;
    }

    .about-page .about-ceo {
        height: 950px;
    }

    .about-page .about-ceo > div {
        width: 96%;
        padding-top: 10px;
        height: 900px;
    }

    .about-page .about-ceo > div > .image {
        width: 100%;
        height: unset;
        margin-left: unset;
    }

    .about-page .about-ceo > div > .image .border-line-one {
        padding: 4px;
        border: 1px solid #0076cd;
        border-radius: 50%;
        width: 260px;
        height: 260px;
    }
    .about-page .about-ceo > div > .image .border-line-one .border-line-two {
        padding: 25px;
        border: 1px solid #0076cd;
        border-radius: 50%;
        width: 250px;
        height: 250px;
    }

    .about-page .about-ceo > div > .image img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin: auto;
    }

    .about-page .about-ceo div .image h3 {
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 22px;
        text-transform: capitalize;
        font-weight: 550;
    }

    .about-page .about-ceo div .image h4 {
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 22px;
        text-transform: capitalize;
        font-weight: 400;
    }

    .about-page .about-ceo div .text {
        width: 90%;
        float: left;
        height: unset;
        margin-left: 5%;
        margin-top: 30px;
    }

    .about-page .about-ceo > div > .text .heading {
        width: 100%;
        height: unset;
        display: flex;
        justify-content: center;
    }

    .about-page .about-ceo > div > .text .heading h3 {
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 22px;
        text-transform: capitalize;
        font-weight: 550;
        margin: auto;
    }

    .about-page .about-ceo > div > .text .description {
        width: 96%;
        margin-left: 2%;
        height: 85%;
        text-align: justify;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 17px;
        font-weight: 350;
        line-height: 25px;
        margin-top: 15px;
    }

    .about-values {
        height: 1200px;
        padding-left: 8%;
        padding-right: 8%;
    }
}

@media only screen and (max-width: 600px) {

    .about-values .single-about-box .single-service .services-icon {
        margin-left: 39%;
    }

    .services-container {
        min-height: 2300px;
    }

    .about-page .introduction {
        height: 400px;
        margin-right: unset;
    }

    .about-page .about-ceo > div > .image .border-line-one {
        padding: 4px;
        border: 1px solid #0076cd;
        border-radius: 50%;
        width: 260px;
        height: 260px;
    }

    .about-page .about-ceo > div > .image .border-line-one .border-line-two {
        padding: 25px;
        border: 1px solid #0076cd;
        border-radius: 50%;
        width: 250px;
        height: 250px;
    }

    .about-values {
        height: 1200px;
        padding-left: 8%;
        padding-right: 8%;
    }
}

@media only screen and (max-width: 500px) {

    .about-page {
        height: 1500px;
    }

    .about-page .introduction {
        height: 400px;
    }

    .about-page .introduction .image-holder .text {
        padding: 0 5%;
        display: block;
    }

    .about-page .introduction .image-holder .text h1 {
        font-size: 25px;
    }

    .about-page .introduction .image-holder .text .square-to-circle-animation {
        margin-left: 46%;
        margin-bottom: 30px;
    }

    .about-page .about-ceo {
        height: 1100px;
    }

    .about-values {
        height: 1330px;
        padding-left: 8%;
        padding-right: 8%;
    }

    .about-values .single-about-box .single-service .services-icon {
        margin-left: 35%;
    }

    .about-values .single-about-box {
        height: 360px;
        width: 100%;
        margin: 0;
        margin-bottom: 30px;
    }

    .about-page .about-ceo > div {
        width: 96%;
        padding-top: 10px;
        height: 1000px;
    }

    .about-page .about-ceo > div > .image {
        width: 100%;
        height: unset;
    }

    .about-page .about-ceo > div > .image .border-line-one {
        padding: 4px;
        border: 1px solid #0076cd;
        border-radius: 50%;
        width: 260px;
        height: 260px;
    }

    .about-page .about-ceo > div > .image .border-line-one .border-line-two {
        padding: 25px;
        border: 1px solid #0076cd;
        border-radius: 50%;
        width: 250px;
        height: 250px;
    }

    .about-page .about-ceo > div > .image img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin: auto;
    }

    .about-page .about-ceo div .image h3 {
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 22px;
        text-transform: capitalize;
        font-weight: 550;
    }

    .about-page .about-ceo div .image h4 {
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 22px;
        text-transform: capitalize;
        font-weight: 400;
    }

    .about-page .about-ceo div .text {
        width: 90%;
        float: left;
        height: unset;
        margin-left: 5%;
        margin-top: 30px;
    }

    .about-page .about-ceo > div > .text .heading {
        width: 100%;
        height: unset;
        display: flex;
        justify-content: center;
    }

    .about-page .about-ceo > div > .text .heading h3 {
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 22px;
        text-transform: capitalize;
        font-weight: 550;
        margin: auto;
    }

    .about-page .about-ceo > div > .text .description {
        width: 96%;
        margin-left: 2%;
        height: 85%;
        text-align: justify;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 17px;
        font-weight: 350;
        line-height: 25px;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 400px) {

    .about-page {
        height: 1600px;
    }

    .about-page .about-ceo > div > .image {
        margin: unset;
    }

    .about-page .about-ceo div .image .image-holder {
    }

    .about-page .introduction .image-holder .text .square-to-circle-animation {
        margin-left: 42%;
        margin-bottom: 30px;
    }

    .about-page .about-ceo {
        height: 1200px;
    }

    .about-page .about-ceo > div {
        margin: unset;
        margin-top: 10px;
    }

    .about-page .about-ceo div .text {
        margin-left: 1%;
    }

    .service-highlight .image-hover .column .description {
        margin-left: unset;
        width: 100%;
        margin-right: unset;
    }
}